import { FC, HTMLAttributes } from "react";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";

import { theme } from "@/theme";

// text size, responsive,

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  text?: any;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  colorVarient?:
    | "primary"
    | "warning"
    | "success"
    | "gray"
    | "purple"
    | "blue"
    | "green"
    | "rose"
    | "blueGray"
    | "indigo"
    | "orange"
    | "error"
    | "moss"
    | "darkBlue";
  leadingIcon?: boolean;
  trailingIcon?: boolean;
  dotLeading?: boolean;
  badgeStyle?: "light" | "dark" | "outline";
  icon?: string;
  height?: string;
}

// const prevSizes: Record<string, "xs" | "sm" | "md" | "lg" | "xl"> = {
//   xs: "xs",
//   sm: "xs",
//   md: "sm",
//   lg: "md",
//   xl: "lg",
// };

// TODO: Convert to a function
const badgeStyleVarients = {
  light: {
    primary: {
      dotColor: theme.colors.primary[500],
      bgColor: theme.colors.primary[50],
      textColor: theme.colors.primary[700],
    },
    gray: {
      dotColor: theme.colors.gray[500],
      bgColor: theme.colors.gray[100],
      textColor: theme.colors.gray[700],
    },
    warning: {
      dotColor: theme.colors.warning[500],
      bgColor: theme.colors.warning[50],
      textColor: theme.colors.warning[700],
    },
    success: {
      dotColor: theme.colors.success[500],
      bgColor: theme.colors.success[50],
      textColor: theme.colors.success[700],
    },
    error: {
      dotColor: theme.colors.error[500],
      bgColor: theme.colors.error[50],
      textColor: theme.colors.error[700],
    },
    purple: {
      dotColor: theme.colors.purple[500],
      bgColor: theme.colors.purple[50],
      textColor: theme.colors.purple[700],
    },
    blue: {
      dotColor: theme.colors.blue[500],
      bgColor: theme.colors.blue[50],
      textColor: theme.colors.blue[700],
    },
    blueGray: {
      dotColor: theme.colors.grayBlue[500],
      bgColor: theme.colors.grayBlue[50],
      textColor: theme.colors.grayBlue[700],
    },
    green: {
      dotColor: theme.colors.green[500],
      bgColor: theme.colors.green[50],
      textColor: theme.colors.green[700],
    },
    rose: {
      dotColor: theme.colors.rose[500],
      bgColor: theme.colors.rose[50],
      textColor: theme.colors.rose[700],
    },
    indigo: {
      dotColor: theme.colors.indigo[500],
      bgColor: theme.colors.indigo[50],
      textColor: theme.colors.indigo[700],
    },
    orange: {
      dotColor: theme.colors.orange[500],
      bgColor: theme.colors.orange[50],
      textColor: theme.colors.orange[700],
    },
  },
  dark: {
    primary: {
      dotColor: theme.colors.primary[200],
      bgColor: theme.colors.primary[600],
      textColor: theme.colors.primary[100],
    },
    gray: {
      dotColor: theme.colors.gray[200],
      bgColor: theme.colors.gray[600],
      textColor: theme.colors.gray[100],
    },
    warning: {
      dotColor: theme.colors.warning[200],
      bgColor: theme.colors.warning[500],
      textColor: theme.colors.warning[100],
    },
    success: {
      dotColor: theme.colors.success[200],
      bgColor: theme.colors.success[600],
      textColor: theme.colors.success[100],
    },
    error: {
      dotColor: theme.colors.error[200],
      bgColor: theme.colors.error[600],
      textColor: theme.colors.error[100],
    },
    purple: {
      dotColor: theme.colors.purple[200],
      bgColor: theme.colors.purple[600],
      textColor: theme.colors.purple[100],
    },
    moss: {
      dotColor: theme.colors.orangeDark[50],
      bgColor: theme.colors.moss[400],
      textColor: theme.colors.orangeDark[50],
    },
    blue: {
      dotColor: theme.colors.blue[200],
      bgColor: theme.colors.blue[600],
      textColor: theme.colors.blue[100],
    },
    blueGray: {
      dotColor: theme.colors.grayBlue[200],
      bgColor: theme.colors.grayBlue[600],
      textColor: theme.colors.grayBlue[100],
    },
    green: {
      dotColor: theme.colors.green[200],
      bgColor: theme.colors.green[600],
      textColor: theme.colors.green[100],
    },
    rose: {
      dotColor: theme.colors.rose[200],
      bgColor: theme.colors.rose[600],
      textColor: theme.colors.rose[100],
    },
    indigo: {
      dotColor: theme.colors.indigo[200],
      bgColor: theme.colors.indigo[600],
      textColor: theme.colors.indigo[100],
    },
    orange: {
      dotColor: theme.colors.orange[200],
      bgColor: theme.colors.orange[600],
      textColor: theme.colors.orange[100],
    },
    darkBlue: {
      dotColor: theme.colors.blueDark[50],
      bgColor: "#444CE7",
      textColor: theme.colors.blueDark[50],
    },
  },
  outline: {
    primary: {
      dotColor: theme.colors.primary[500],
      bgColor: "none",
      textColor: theme.colors.primary[700],
    },
    gray: {
      dotColor: theme.colors.gray[500],
      bgColor: "none",
      textColor: theme.colors.gray[700],
    },
    warning: {
      dotColor: theme.colors.warning[500],
      bgColor: "none",
      textColor: theme.colors.warning[700],
    },
    success: {
      dotColor: theme.colors.success[500],
      bgColor: "none",
      textColor: theme.colors.success[700],
    },
    error: {
      dotColor: theme.colors.error[500],
      bgColor: "none",
      textColor: theme.colors.error[700],
    },
    purple: {
      dotColor: theme.colors.purple[500],
      bgColor: "none",
      textColor: theme.colors.purple[700],
    },
    blue: {
      dotColor: theme.colors.blue[500],
      bgColor: "none",
      textColor: theme.colors.blue[700],
    },
    blueGray: {
      dotColor: theme.colors.grayBlue[500],
      bgColor: "none",
      textColor: theme.colors.grayBlue[700],
    },
    green: {
      dotColor: theme.colors.green[500],
      bgColor: "none",
      textColor: theme.colors.green[700],
    },
    rose: {
      dotColor: theme.colors.rose[500],
      bgColor: "none",
      textColor: theme.colors.rose[700],
    },
    indigo: {
      dotColor: theme.colors.indigo[500],
      bgColor: "none",
      textColor: theme.colors.indigo[700],
    },
    orange: {
      dotColor: theme.colors.orange[500],
      bgColor: "none",
      textColor: theme.colors.orange[700],
    },
  },
};

const sizeVarients = {
  xs: { padding: "2px 8px 2px 8px", borderRadius: "16px", fontSize: "10px" },
  sm: { padding: "4px 12px 4px 12px", borderRadius: "16px", fontSize: "12px" },
  md: { padding: "6px 12px 6px 12px", borderRadius: "16px", fontSize: "14px" },
  lg: { padding: "8px 12px 8px 12px", borderRadius: "20px", fontSize: "16px" },
  xl: { padding: "8px 16px 8px 16px", borderRadius: "20px", fontSize: "18px" },
};

// typography styling is missing

const Badge: FC<BadgeProps> = ({
  text,
  icon = "arrow-up",
  dotLeading = false,
  leadingIcon = false,
  trailingIcon = false,
  badgeStyle = "dark",
  size = "sm",
  height,
  colorVarient = "primary",
  ...rest
}) => {
  const { dotColor, bgColor, textColor } = badgeStyleVarients[badgeStyle][colorVarient];

  const { padding, borderRadius, fontSize } = sizeVarients[size];
  return (
    <Box
      style={{ alignItems: "center" }}
      p={padding}
      bg={bgColor}
      height={height ? height : "unset"}
      display={"inline-flex"}
      borderRadius={borderRadius}
      border={badgeStyle === "outline" ? `1.5px solid ${dotColor}` : "none"}
      {...rest}
    >
      {dotLeading && (
        <Box
          width={6}
          height={6}
          borderRadius="50%"
          bg={dotColor || theme.colors.purple[200]}
          mr={"4px"}
          data-testid="dot-leading"
        />
      )}
      {leadingIcon && (
        <Box mr={"4px"} data-testid="leading-icon">
          <Icon size={12} color={dotColor} type={icon!} varient={IconVarient.line} />
        </Box>
      )}
      <Box color={textColor} textAlign={"center"} fontSize={fontSize}>
        {text}
      </Box>
      {trailingIcon && (
        <Box ml={"4px"} data-testid="trailing-icon">
          <Icon size={12} color={dotColor} type={icon!} varient={IconVarient.solid} />
        </Box>
      )}
    </Box>
  );
};

export default Badge;
