import Box from "@/design-system/Box";
import PrimaryButton from "@/design-system/Button/PrimaryButton";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";
import { theme } from "@/theme";
import Link from "next/link";
import styled from "styled-components";
import NavbarAcountInfo from "./NavbarAccountInfo";

const MenuIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing(2)};
  justify-content: center;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const RightNavStyle = styled.div`
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: span 3;
  }
`;

const RightNav = ({
  setIsopenSidebar,
  isOpenSidebar,
  disableCta,
  showContactUs,
}: any) => {
  return (
    <RightNavStyle>
      <Box display={"inherit"}>
        {showContactUs && (
          <Link href={{ pathname: "/contact-us" }}>
            <PrimaryButton
              size={"md"}
              mr={2}
              label="Contact Us"
              className="button__contact_us"
            />
          </Link>
        )}

        {!disableCta && (
          <NavbarAcountInfo isWeb displayProp={{ _: "none", md: "block" }} />
        )}
      </Box>

      {!disableCta && (
        <MenuIcon onClick={() => setIsopenSidebar(!isOpenSidebar)}>
          <Icon type={IconType["menu-04"]} varient={IconVarient.solid} />
        </MenuIcon>
      )}
    </RightNavStyle>
  );
};

export default RightNav;
