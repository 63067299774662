import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import Link from "next/link";
import styled from "styled-components";

const CoursesBoxStyle = styled.div`
  padding: 24px 16px;
  margin-top: 24px;
  background-color: inherit;
`;

const CourseLinkStyle = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${theme.colors.primary[100]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CoursesLink = ({ setIsNavMobileOpen }: any) => {
  return (
    <CoursesBoxStyle>
      <Typography varient="labels" ml={"8px"}>
        COURSES
      </Typography>

      <CourseLinkStyle>
        <Link
          href={"/courses/building-information-modelling"}
          onClick={() => setIsNavMobileOpen(false)}
        >
          <Typography
            varient="subheadingl"
            weightVarient="medium"
            color={theme.colors.gray[800]}
          >
            BIM for Architects
          </Typography>
        </Link>
      </CourseLinkStyle>
      <CourseLinkStyle>
        <Link
          href={"/courses/computational-design"}
          onClick={() => setIsNavMobileOpen(false)}
        >
          <Typography
            varient="subheadingl"
            weightVarient="medium"
            color={theme.colors.gray[800]}
          >
            Master Computational Design
          </Typography>
        </Link>
      </CourseLinkStyle>
      <CourseLinkStyle>
        <Link
          href={"/courses/bim-for-civil-engineers"}
          onClick={() => setIsNavMobileOpen(false)}
        >
          <Typography
            varient="subheadingl"
            weightVarient="medium"
            color={theme.colors.gray[800]}
          >
            BIM for Civil engineers
          </Typography>
        </Link>

        {/* <Badge
          text={"Coming Soon"}
          badgeStyle={"light"}
          colorVarient={"warning"}
          dotLeading={true}
          size={"md"}
        /> */}
      </CourseLinkStyle>
    </CoursesBoxStyle>
  );
};

export default CoursesLink;
