import { theme } from "@/theme";
import styled from "styled-components";
import { GradientLineInterface } from "./types";

export const StyledGradientLine = styled.div<GradientLineInterface>`
  background-image: ${({ color }) => color || theme.gradients.misc[4]};
  z-index: 1;
  width: 4px;
  height: ${({ height }) => height || "auto"};
  border-radius: 2px;
`;
