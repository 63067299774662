import Typography from "@/design-system-v3/Typography";
import Box from "@/design-system/Box";
import { theme } from "@/theme";
import Link from "next/link";
import styled from "styled-components";
const Company = () => {
  return (
    <Box
      borderRight={{ md: `1px solid ${theme.colors.gray[800]}` }}
      borderBottom={{ xs: `1px solid ${theme.colors.gray[800]}`, md: "none" }}
      p={{ xs: "24px", md: "40px" }}
    >
      <Box mb={"24px"}>
        <Typography pb={"8px"} varient="bodyl" color={"gray.500"}>
          COMPANY
        </Typography>
        <Box
          height={"1px"}
          backgroundImage={"linear-gradient(to right,#344054, #34405400)"}
        />
      </Box>
      <Link className="link__" href={"/about"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          About Us
        </LinkComp>
      </Link>
      <Link className="link__" href={"/courses/building-information-modelling"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          BIM Professional Course for Architects
        </LinkComp>
      </Link>
      <Link className="link__" href={"/courses/computational-design"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          Computational Design
        </LinkComp>
      </Link>
      <Link className="link__" href={"/courses/bim-for-civil-engineers"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          BIM Professional Course for Civil Engineers
        </LinkComp>
      </Link>
      <Link className="link__" href={"/apply-as-mentor"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          Become a Mentor
        </LinkComp>
      </Link>
      <Link className="link__" href={"/hire-from-us"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          Hiring Partners
        </LinkComp>
      </Link>
      <Link className="link__" href={"/career"}>
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          Careers at Novatr
        </LinkComp>
      </Link>
      <Link
        className="link__"
        target="_blank"
        href={"https://www.novatr.com/media-room-home-page"}
      >
        <LinkComp
          mb={"8px"}
          varient="bodym"
          weightVarient="semibold"
          color={"base.white"}
        >
          Media Room
        </LinkComp>
      </Link>
    </Box>
  );
};

export default Company;

const LinkComp = styled(Typography)`
  &:hover {
    color: ${theme.colors.base.white};
  }
`;
