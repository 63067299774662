import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import GrayButton from "@/design-system/Button/GrayButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";
import Typography from "@/design-system/Typography";
import GradientLine from "@/design-system/Utility/GradientLine";

import { theme } from "@/theme";

const PartnersCard = styled.div`
  background-color: ${theme.colors.gray[100]};
  border-radius: 16px;
  grid-column: span 6;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: ${theme.spacing(6)} 0 ${theme.spacing(6)} ${theme.spacing(16)};
`;

const Resources = () => {
  return (
    <>
      <PartnersCard>
        <div>
          <FlexContainer alignItems={"center"} mb={2}>
            <GradientLine height={"72px"} color={theme.gradients.misc[7]} />
            <Typography
              ml={theme.spacing(4)}
              color={"primary.900"}
              varient="heading3"
              weightVarient="semibold"
            >
              Become a <br /> Mentor
            </Typography>
          </FlexContainer>
          <Typography varient="bodyxs" mb={theme.spacing(13)}>
            Enable young professionals to make their greatest career leap.
          </Typography>
          <Link href={{ pathname: "/apply-as-mentor" }}>
            <GrayButton
              label="Learn More"
              className="button__learn_more"
              suffixIcon={<Icon type={IconType["arrow-narrow-right"]} varient={IconVarient.line} />}
            >
              Learn More
            </GrayButton>
          </Link>
        </div>
        <ExportedImage
          width={308}
          height={320}
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mentor.png`}
          //src={"${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/partners/become-mentor.png"}
          alt="Become a Mentor"
        />
      </PartnersCard>
      <PartnersCard>
        <div>
          <FlexContainer alignItems={"stretch"} mb={2}>
            <GradientLine color={theme.gradients.misc[2]} />
            <Typography
              ml={theme.spacing(4)}
              color={"primary.900"}
              varient="heading3"
              weightVarient="semibold"
            >
              Become a Hiring Partner
            </Typography>
          </FlexContainer>

          <Typography varient="bodyxs" mb={theme.spacing(13)}>
            Hire highly skilled AEC professionals at zero cost.
          </Typography>
          <Link href={{ pathname: "/hire-from-us" }}>
            <GrayButton
              label="Learn More"
              className="button__learn_more"
              suffixIcon={<Icon type={IconType["arrow-narrow-right"]} varient={IconVarient.line} />}
            >
              Learn More
            </GrayButton>
          </Link>
        </div>

        <ExportedImage
          width={308}
          height={320}
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/hiring_partner.png`}
          alt="Become a Hiring Partner"
        />
      </PartnersCard>
    </>
  );
};

export default Resources;
